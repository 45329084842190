import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { handleClientStatementOptionsFilteration, handleClientStatementFilteration, getClientStatementEndpoint, getClientStatementPDFEndpoint, getClientStatementCSVEndpoint } from "../../../components/src/Utils/utils"
import { shouldUpdateclientStatementTable } from "../../../components/src/Utils/parseData";
import ClientStatementPDFTemplate from "../../../components/src/PdfTemplate/ClientStatementPDFTemplate.web";
import { jsPDF } from 'jspdf'
import { renderToString } from "react-dom/server";



export interface Props {
  classes: any;
  navigation: any;
}

interface Client {
  id: number;
  declaration_name: string;
  is_delinquent_enable: boolean;
  company_name: string;
}

type token = string | null

interface S {
  token: token
  isLoading: boolean;
  page: number;
  per_page: number;
  count: number;
  isClientsLoad1: boolean;
  clientOptionsResponse: any;
  pdfURL: string;
  excelURL: string;
  disbursmentStartDate: string;
  disbursmentEndDate: string;
  dueStartDate: string;
  dueEndDate: string;
  statementData: any[];
  isDelinquentFeeAvailable: any[];
  clientName: string;
  CompanyName: string;
  clientAddress: string;
  pendingAmmount: string;
  columnTitle: string;
  search: { id: string | number; name: string };
  searchSelect: string,
  entireStatementData: any
  isAmountCredit: any;
}

interface SS {
  id: any;
}

export default class ClientStatementsController extends BlockComponent<Props, S, SS> {
  getStatementDataAPI: string = "";
  getClientOptionsAPI: string = "";
  debounceTimeOutFunction: any = null;
  getDocDownloadPDFAPI: string = "";
  getDocDownloadExcelAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      token: '',
      isLoading: false,
      page: 1,
      count: 0,
      per_page: 10,
      isClientsLoad1: false,
      clientOptionsResponse: [],
      pdfURL: '',
      excelURL: '',
      disbursmentStartDate: "",
      disbursmentEndDate: "",
      dueStartDate: "",
      dueEndDate: "",
      statementData: [],
      isDelinquentFeeAvailable: [],
      clientName: "",
      CompanyName: "",
      clientAddress: "",
      pendingAmmount: "",
      columnTitle: '',
      search: { id: "", name: "" },
      searchSelect: "",
      entireStatementData: {},
      isAmountCredit: false
    };
  }

  async componentWillMount() {
    if (!localStorage.getItem("auth_token")) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "login");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      const token = localStorage.getItem("auth_token");
      this.setState({ token: token, isLoading: true });
      this.getStatementDataAPI = this.getStatementData(token)
      this.getDocDownloadPDFAPI = this.getDocDownloadPDF(token)
      this.getDocDownloadExcelAPI = this.getDocDownloadExcel(token)
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const isUpdate = shouldUpdateclientStatementTable(prevState, this.state);
    if (isUpdate) {
      const endPoint = getClientStatementEndpoint(this.state)
      const endPoint2 = getClientStatementCSVEndpoint(this.state)
      const endPoint3 = getClientStatementPDFEndpoint(this.state)
      this.getStatementDataAPI = this.statementApiCall(this.state.token, endPoint, "GET")
      this.getDocDownloadPDFAPI = this.statementApiCall(this.state.token, endPoint2, "GET")
      this.getDocDownloadExcelAPI = this.statementApiCall(this.state.token, endPoint3, "GET")
    }
  }

  componentWillUnmount(): any {
    if (this.debounceTimeOutFunction) {
      clearTimeout(this.debounceTimeOutFunction);
    }
  }

  clearStatementFilters = () => {
    this.setState(
      {
        columnTitle: "",
        searchSelect: "",
        page: 1,
        per_page: 10,
        disbursmentStartDate: "",
        search: { id: 0, name: "" },
        disbursmentEndDate: "",
        dueStartDate: "",
        dueEndDate: "",
      },
      () => {
        this.getDocDownloadExcelAPI = this.statementApiCall(this.state.token, `statement_excel`, "GET")
        this.getDocDownloadPDFAPI = this.statementApiCall(this.state.token, `get_statements`, "GET")
        this.getStatementDataAPI = this.statementApiCall(this.state.token, `get_statements?page=1&per_page=10`, "GET");
      }
    );
  }


  handleSelectSearch = (event: any, value: any) => {
    if (value?.id) {
      this.setState({
        searchSelect: value.number
      });
    } else {
      this.setState({ search: { id: 0, name: "" } });
    }
  };

  handleFilter = (col: any, opt: any) => {
    if (col === "CCI_settlement_date")
      this.setState({columnTitle: this.state.columnTitle === opt ? "" : opt})
  };

  handleChangePage = (event: any, newPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: newPage,
    });
  };

  handleClientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    this.setState({ search: { id: 0, name: inputValue } });
    if (inputValue === "") {
      this.setState({ clientOptionsResponse: [] });
    }
    if (this.debounceTimeOutFunction) {
      clearTimeout(this.debounceTimeOutFunction);
    }
    this.debounceTimeOutFunction = setTimeout(() => {
      this.setState({ isClientsLoad1: true })
      this.getClientOptionsAPI = this.statementApiCall(this.state.token, `search_statement?search=${inputValue}`, "GET");
    }, 400);
  };

  handleClientOptionsResponse = (response: any) => {
    if (response?.length > 0) {
      const filterOptionData = handleClientStatementOptionsFilteration(response);
      this.setState({
        clientOptionsResponse: filterOptionData,
      })
    } else {
      this.setState({ clientOptionsResponse: [] });
    }
    this.setState({ isClientsLoad1: false });
  };

  handleDateChange = (startDate: string, endDate: string, label: string) => {
    if (label === "Disbursement Date") {
      this.setState({ disbursmentStartDate: startDate, disbursmentEndDate: endDate })
    } else if (label === "Due Date") {
      this.setState({ dueStartDate: startDate, dueEndDate: endDate })
    }
  }

  handleClientStatementResponse = (response: any) => {
    const settlementDateValue =  this.splitString()
    const filterdData = handleClientStatementFilteration(response,settlementDateValue)
    const isDelinquentFee = filterdData?.filter((elm: any) => elm?.delinquent_fee && elm?.delinquent_fee !== "J$ 0.00")
    this.setState({
      statementData: filterdData,
      isDelinquentFeeAvailable: isDelinquentFee,
      clientName: response.contact_name,
      CompanyName: response.company_name,
      clientAddress: response.address,
      pendingAmmount: response.total_payment_pending,
      isLoading: false,
      count: Math.ceil(response.total_count / this.state.per_page || 1),
      isAmountCredit: response.is_paid
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getClientOptionsAPI) {
      this.handleClientOptionsResponse(responseJson)
    }
    if (apiRequestCallId === this.getStatementDataAPI) {
      this.handleClientStatementResponse(responseJson)
    }
    if (apiRequestCallId === this.getDocDownloadPDFAPI) {
      responseJson && this.setState({ entireStatementData: responseJson });
    }
    if (apiRequestCallId === this.getDocDownloadExcelAPI) {
      responseJson?.url && this.setState({ excelURL: responseJson.url });
    }
  }

  getStatementData = (token: token) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `get_statements?page=1&per_page=10`
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);

    return clientRequestMessage.messageId
  }

  getDocDownloadPDF = (token: token) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `get_statements`
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);

    return clientRequestMessage.messageId
  }

  getDocDownloadExcel = (token: token) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `statement_excel`
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);

    return clientRequestMessage.messageId
  }

  statementApiCall = (token: token, endPoint: string, method: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);

    return clientRequestMessage.messageId
  }
  

  downloadPdf = () => {
    const { entireStatementData, isDelinquentFeeAvailable } = this.state;
    const settlementDateValue = this.splitString();
  
    const pdfContainer = document.createElement('div');
    document.body.appendChild(pdfContainer);
  
    const htmlContent = renderToString(
      <ClientStatementPDFTemplate
        pdfData={entireStatementData}
        isShow={isDelinquentFeeAvailable}
        settlementDateValue={settlementDateValue}
      />
    );

    
    const containerWidth = pdfContainer.offsetWidth;
    const width = containerWidth;
    const windowWidth = containerWidth;
    const pdf = new jsPDF('landscape', 'mm', 'a1');
  
    const headerHeight = 40;
    const padding = 10;
  
    pdf.html(htmlContent, {
      callback: (pdf : any) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          const pageHeight = pdf.internal.pageSize.height;
          const pageWidth = pdf.internal.pageSize.width;
          if (i !== 1) {
            pdf.setFillColor(245, 235, 205);
            pdf.rect(10, 10, pageWidth - 20, headerHeight, 'F');
  
            pdf.setFontSize(28);
            pdf.setTextColor(0, 0, 0);
  
            
            pdf.text("Disbursement", 20 + 0, 20 + padding);
            pdf.text("Date", 38 + 0, 32 + padding);
  
            
            pdf.text("Due Date", 100 + padding, 20 + padding);
  
            
            pdf.text("Amount", 183 + padding, 18 + padding);
            pdf.text("Disbursed", 180 + padding, 32 + padding);
  
            
            pdf.text("Factor Fee", 260 + padding, 20 + padding);
  
            
            pdf.text("Amount to be", 340 + padding, 18 + padding);
            pdf.text("Settled to CCI", 340 + padding, 32 + padding);
  
            
            pdf.text("Invoice/PO ", 425 + padding, 20 + padding);
            pdf.text("#", 440 + padding, 32 + padding);
  
            
            pdf.text("Invoice Amount", 500 + padding, 20 + padding);
  
            
            pdf.text("Vendor/", 585 + padding, 20 + padding);
            pdf.text("Buyer", 590 + padding, 32 + padding);
  
            
            pdf.text("Invoice/PO ", 650 + padding, 20 + padding);
            pdf.text("Date ", 660 + padding, 32 + padding);
  
            
            pdf.text("CCI Settlement", 750 + 0, 18 + padding);
            pdf.text("Date", 770 + 0, 32 + padding);
          }
  
          
          const paginationText = `Page ${i} of ${totalPages}`;
          const textWidth = pdf.getTextWidth(paginationText);
          pdf.text(paginationText, (pageWidth - textWidth) / 2, pageHeight - 10);
        }
  
        pdf.save('Client_statement.pdf');
        document.body.removeChild(pdfContainer);
      },
      x: 0,
      y: 0,
      margin: [60, 0, 30, 0],
      autoPaging: 'text',
      width: width,
      windowWidth: windowWidth,
    });
  };

  splitString=()=>{
    let endPoint = getClientStatementEndpoint(this.state)
    const keyValuePairs = endPoint.split('&');
    let settlementDateValue =true;
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split('=');
      if (key === 'settlement_date') {
        settlementDateValue = false
        break;
      }
    }
    return settlementDateValue
  }
}


